.MuiOutlinedInput-notchedOutline {
  border-color: #8c8c8c !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
  color: white;
}

.app-icon {
  width: 150px
}

fieldset {
  border: 1px solid #000;
}

.open {
  font-family: "Open Sans";
}

.spartan {
  font-family: "League Spartan";
}